.container {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  padding-bottom: 2em;
  /* padding-right: 2em;
  padding-right: 2em; */
}

.form {
  margin-left: auto;
  margin-right: auto;
}

.appImg {
  margin: auto;
  width: 20vw;
}

.appImg:hover, .appLogo:hover {
 opacity: 0.7;
}

.appLogo {
  margin: auto;
  width: 10vw;
}

p,
h1 {
  text-align: center;
}

p a {
  text-decoration: none;
}

.description {
  /* font-weight: 600; */
  padding: 1rem;
}

h1 {
  font-weight: 600;
}

a:hover {
  color: #ff00e5;
}

/* .list{
  align-content: flex-start;
  display: list-item;
} */

.list li,
ol {
  padding: 0.5rem;
}

.list {
  max-width: 90%;
}
/* .row {
  flex-direction: row;
} */

@media only screen and (max-width: 600px) {
  .appImg {
    width: 70vw;
  }
  .appLogo {
    margin: auto;
    width: 20vw;
  }
}

* {
  font-family: "Courier New", monospace;
  /* font-weight: 500; */
}

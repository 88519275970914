.footerList {
  display: flex;
  align-items: center; /* For vertical alignment */
  justify-content: center;
  flex-direction: column;
  /* position: fixed; */
  padding: 0 0 2em 0;
  margin: auto;
  width: 100%;
}

.linkList {
  display: flex;
  align-items: center; /* For vertical alignment */
  justify-content: center;
  flex-direction: row;
  margin: auto;
  /* padding: 1em 0 1em 0; */
}

.link {
  padding: 1rem 1rem;
}

.navBar {
  /* background-color: #f8f9fa; */
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); */
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

}

.navList {
  list-style: none;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

a {
  /* padding: 1em; */
  color: #333;
  text-decoration: none;
}
